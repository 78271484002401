//Create a footer component that includes social media icons and copyright info
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGithub,faLinkedin, faXTwitter} from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
        <footer className="footer text-center">
            <h5>Get in touch with me</h5>
            <div className="social-icons m-3">
                <a href="https://github.com/chitech" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub}/></a>
                <a href="https://www.linkedin.com/in/christinebrownclayton/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon= {faLinkedin} /></a>
                <a href="https://twitter.com/chitechpro" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>
            </div>
            <div className="copyright">
                &copy; 2024-2025 Christine Brown Clayton. All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;
