import React from 'react';
import backgroundImage from "../assets/images/chitech_abstract.png";
import unierosScreen from "../assets/images/UnierosScreenshot.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faBootstrap, faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faClock, faRobot, faCloud, faMobile, faGears } from '@fortawesome/free-solid-svg-icons';
import { faAws } from '@fortawesome/free-brands-svg-icons';

import Contact from './Contact';
import { Link } from 'react-router-dom';

const Home = () => {
  const jumbotronStyle = {
    backgroundImage: `linear-gradient(rgba(30, 41, 59, 0.95), rgba(51, 65, 85, 0.9)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
    padding: "0 20px",
    position: "relative",
    transition: "all 0.3s ease",
  };

  return (
    <div className="container-fluid p-0">
      {/* Hero Section */}
      <div className="jumbotron jumbotron-fluid" style={jumbotronStyle}>
        <div className="container">
          <h1 className="display-4">Technical Consulting & Enterprise Solutions</h1>
          <p className="lead">Over 10 years of experience transforming businesses through technology</p>
          <a href="https://calendly.com/unieros/30min" className="btn btn-primary btn-lg">Schedule a Consultation</a>
        </div>
      </div>

      {/* Specialties Section */}
      <section className="specialties py-5">
        <div className="container">
          <h2 className="text-center mb-5">Areas of Expertise</h2>
          <div className="row g-4">
            <div className="col-md-6">
              <div className="specialty-card p-4 bg-light rounded">
                <FontAwesomeIcon icon={faRobot} className="text-primary mb-3" size="2x" />
                <h5>AI & Automation for Business Efficiency</h5>
              </div>
            </div>
            <div className="col-md-6">
              <div className="specialty-card p-4 bg-light rounded">
                <FontAwesomeIcon icon={faCloud} className="text-primary mb-3" size="2x" />
                <h5>Cloud-based Application Development</h5>
                <p className="text-muted">AWS, Azure, Google Cloud</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="specialty-card p-4 bg-light rounded">
                <FontAwesomeIcon icon={faMobile} className="text-primary mb-3" size="2x" />
                <h5>Digital Marketing & Social Media Growth</h5>
              </div>
            </div>
            <div className="col-md-6">
              <div className="specialty-card p-4 bg-light rounded">
                <FontAwesomeIcon icon={faGears} className="text-primary mb-3" size="2x" />
                <h5>Workflow Automation & Enterprise Solutions</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Consultation Benefits Section */}
      <section className="consultation py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-5">Book a 30-Minute Strategy Session</h2>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h4 className="card-title mb-4">During this session, you can:</h4>
                  <ul className="list-unstyled">
                    <li className="mb-3">
                      <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                      Get expert advice on AI integration and automation
                    </li>
                    <li className="mb-3">
                      <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                      Learn how to optimize your website & digital presence
                    </li>
                    <li className="mb-3">
                      <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                      Get strategies to increase user engagement
                    </li>
                    <li className="mb-3">
                      <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                      Explore cloud-based solutions & API integrations
                    </li>
                    <li className="mb-3">
                      <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                      Ask any tech-related questions for your business
                    </li>
                  </ul>
                  <div className="text-center mt-4">
                    <a href="https://calendly.com/unieros/30min" className="btn btn-primary btn-lg">Schedule Your Session</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="about container my-5 py-5">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img className="img-fluid headshot" src={require("../assets/images/headshot.jpeg")} alt="Christine headshot" />
          </div>
          <div className="col-md-6">
            <h2>About Me</h2>
            <p className="lead">
              Hello! I'm Christine, a passionate web developer with a focus on building platforms that bring people together.
            </p>
            <p>
              My journey began with a curiosity for technology, leading me to create my first website using Drupal CMS. Since then, I've dedicated myself to developing user-friendly web solutions that bridge cultures and enhance connections.
            </p>
            <Link to="/about" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
      </section>

      {/* Certifications Section */}
      <section className="certifications py-5 bg-white">
        <div className="container">
          <h2 className="text-center mb-5">Certifications</h2>
          <div className="row justify-content-center g-4">
            <div className="col-md-6">
              <div className="certification-card p-4 text-center hover-card">
                <FontAwesomeIcon icon={faAws} size="3x" className="text-primary mb-4" />
                <h4>AWS Certified:</h4>
                <h5 className="mb-3">Cloud Practitioner</h5>
                <p className="text-muted">
                  Comprehensive understanding of AWS Cloud concepts, services, 
                  architecture, pricing, and security.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="certification-card p-4 text-center hover-card">
                <FontAwesomeIcon icon={faCloud} size="3x" className="text-primary mb-4" />
                <h4>Microsoft Certified:</h4>
                <h5 className="mb-3">Azure AI Fundamentals</h5>
                <p className="text-muted">
                  Validated expertise in AI concepts, Microsoft Azure cognitive services, 
                  and responsible AI principles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;